<template>
  <form
    @submit.prevent=""
    :data-test="globalSelector ? 'global-criteria' : 'column-criteria'"
  >
    <div :class="[{ 'form-disabled': disabled }]">
      <single-select
        data-test="characteristics"
        v-if="showCharacteristics"
        :options="savedPopulations"
        :getOptionLabel="
          (opt) => (opt.filterData ? opt.filterData.populationName : '--')
        "
        optionValue="id"
        fieldLabel="My Population Characteristics"
        :clearable="false"
        class="rfs-m-8-t"
        style="width: 100%"
        v-model="characteristicsSelected"
        @input="updateCharacteristicsSelected(characteristicsSelected)"
        :key="criteriaRedrawKeyFormatted('characteristics')"
      />

      <date-range
        data-test="date-range"
        v-if="showDateRange"
        class="rfs-m-8-b form-row"
        :disabled="disabled"
        v-model="dateRangeSelected"
        @input="
          onUpdateDate();
          updateDateRangeSelected(dateRangeSelected);
        "
      />
      <!-- v-model does nothing right now because I am not sure what your store stuff will look like -->
      <!-- but for the sake of making the component consitant, I roughed in v-model and the emit stuff -->
      <any-segment-select
        data-test="any-segment"
        v-if="showDataSegment"
        stacked
        :updateStore="false"
        v-model="segmentSelected"
        style="margin-top: 12px"
        :disableControls="disabled"
        :hospitalOptions="hospitals"
        class="sm:col-span-9 md:col-span-12 lg:col-span-8 xl:col-span-9"
        @input="updateDataSegmentSelected(segmentSelected)"
      />
      <multi-select
        data-test="patient-status"
        v-if="showPatientStatus"
        class="rfs-m-8-b form-row"
        v-model="patientStatusSelected"
        @input="updatePatientStatusSelected(patientStatusSelected)"
        :options="patientStatusOptions"
        optionLabel="description"
        optionValue="code"
        fieldLabel="Patient Type"
        ref="patientStatusSelector"
        :reduce="(patient) => patient.code"
        :key="criteriaRedrawKeyFormatted('patientStatus')"
      >
        <template #list-header>
          <li class="rfs-m-2 cursor-pointer checkbox">
            <label
              ><input
                @click.prevent="focusInput('patientStatusSelector')"
                type="checkbox"
                :checked="
                  patientStatusSelected.length === patientStatusOptions.length
                "
                @click="
                  [
                    selectDeselectAll(
                      'patientStatusOptions',
                      'patientStatusSelected'
                    ),
                    updatePatientStatusSelected(patientStatusSelected)
                  ]
                "
              />
              <span class="helper"></span>Select All Types</label
            >
          </li>
        </template>
      </multi-select>
      <multi-select
        data-test="gender"
        v-if="showGender"
        class="rfs-m-8-b form-row"
        v-model="genderSelected"
        @input="updateGenderSelected(genderSelected)"
        :options="genderOptions"
        optionLabel="description"
        optionValue="code"
        fieldLabel="Gender"
        ref="genderSelector"
        :reduce="(gender) => gender.code"
        :key="criteriaRedrawKeyFormatted('gender')"
      >
        <template #list-header>
          <li class="rfs-m-2 cursor-pointer checkbox">
            <label
              ><input
                @click.prevent="focusInput('genderSelector')"
                type="checkbox"
                :checked="genderSelected.length === genderOptions.length"
                @click="
                  [
                    selectDeselectAll('genderOptions', 'genderSelected'),
                    updateGenderSelected(genderSelected)
                  ]
                "
              />
              <span class="helper"></span>Select All Genders</label
            >
          </li>
        </template>
      </multi-select>
      <multi-select
        data-test="age-range"
        v-if="showAgeRange"
        class="rfs-m-8-b form-row"
        v-model="ageRangeSelected"
        @input="updateAgeRangeSelected(ageRangeSelected)"
        :options="ageRangeOptions"
        optionLabel="description"
        optionValue="code"
        fieldLabel="Age Range"
        ref="ageSelector"
        :reduce="(age) => age.code"
      >
        <template #list-header>
          <li class="rfs-m-2 cursor-pointer checkbox">
            <label
              ><input
                @click.prevent="focusInput('ageSelector')"
                type="checkbox"
                :checked="ageRangeSelected.length === ageRangeOptions.length"
                @click="
                  [
                    selectDeselectAll('ageRangeOptions', 'ageRangeSelected'),
                    updateAgeRangeSelected(ageRangeSelected)
                  ]
                "
              />
              <span class="helper"></span>Select All Age Range</label
            >
          </li>
        </template>
      </multi-select>
    </div>
  </form>
</template>

<script>
  import AnySegmentSelect from '@/components/filters/AnySegmentSelect.vue';
  import DateRange from '@/components/base/forms/date_range';
  import MultiSelect from '@/components/base/forms/multi_select';
  import SingleSelect from '@/components/base/forms/single_select';

  // Filter Choice Data Bits
  import { genders, ageRanges, patientStatus } from '@/data';

  export default {
    components: {
      AnySegmentSelect,
      DateRange,
      MultiSelect,
      SingleSelect
    },
    props: [
      'populationStore',
      'workflowStore',
      'globalSelector',
      'criteriaRedrawKey',

      'segmentValue',
      'disabled'
    ],
    data() {
      return {
        genderOptions: genders,
        ageRangeOptions: ageRanges,
        patientStatusOptions: patientStatus,
        segmentSelected: this.segmentValue,
        savedPopulations: [],
        savedPopulationValue: '',
        characteristicsSelected: {},
        genderSelected: [],
        dateRangeSelected: {},
        ageRangeSelected: [],
        patientStatusSelected: []
      };
    },
    watch: {
      criteriaRedrawKey: {
        deep: true,
        handler() {
          this.genderSelected = this.workflowStore.getGender();
          this.dateRangeSelected = this.workflowStore.getDateRange();
          this.ageRangeSelected = this.workflowStore.getAgeRange();
          this.patientStatusSelected = this.workflowStore.getPatientStatus();
          this.characteristicsSelected =
            this.populationStore.getCurrentPopulationSelection();
        }
      }
    },
    computed: {
      hospitals() {
        let hospitals = this.$store.getters['auth/userHospitalList'];
        let options = [];

        for (let i in hospitals) {
          options.push({ text: hospitals[i].name, value: hospitals[i].id });
        }

        return options;
      },
      showCharacteristics() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('characteristics')
          : !this.workflowStore.isComparisonDimensionGlobal('characteristics');
      },
      showDateRange() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('date_range')
          : !this.workflowStore.isComparisonDimensionGlobal('date_range');
      },
      showDataSegment() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('data_segment')
          : !this.workflowStore.isComparisonDimensionGlobal('data_segment');
      },
      showPatientStatus() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('patient_status')
          : !this.workflowStore.isComparisonDimensionGlobal('patient_status');
      },
      showGender() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('gender')
          : !this.workflowStore.isComparisonDimensionGlobal('gender');
      },
      showAgeRange() {
        return this.globalSelector
          ? this.workflowStore.isComparisonDimensionGlobal('age_range')
          : !this.workflowStore.isComparisonDimensionGlobal('age_range');
      }
    },
    methods: {
      updateDataSegmentSelected(value) {
        if (!this.workflowStore.isCurrentDataSegmentEqual(value)) {
          this.workflowStore.setDataSegment(value);
          this.populationStore.setDirty(true);
        }

        this.$emit('data-segment-set', value);
      },
      updateCharacteristicsSelected(value) {
        this.populationStore.setCurrentPopulationSelection(value);
        this.populationStore.loadSavedPopulationByFilterData(value);
        this.$emit('characteristics-set', value);
      },
      updateGenderSelected(value) {
        this.workflowStore.setGender(value);
        this.$emit('gender-set', value);
      },
      updateDateRangeSelected(value) {
        this.workflowStore.setDateRange(value);
        this.$emit('date-range-set', value);
      },
      updateAgeRangeSelected(value) {
        this.workflowStore.setAgeRange(value);
        this.$emit('age-range-set', value);
      },
      updatePatientStatusSelected(value) {
        this.workflowStore.setPatientStatus(value);
        this.$emit('patient-status-set', value);
      },
      selectDeselectAll(o, s) {
        if (this[o].length === this[s].length) {
          this[s].splice(0, this[s].length);
        } else {
          var selected = this[o].map((obj) => {
            return obj.code;
          });
          this[s] = selected;
        }
      },
      isSelected(c, s) {
        return this[s].find(({ code }) => code === c);
      },
      focusInput(ref) {
        this.$refs[ref].$children[0].$refs.search.focus();
      },
      populateSavedPopulationList(setValue) {
        this.$store
          .dispatch('populationManagement/getSavedPopulationsList')
          .then(() => {
            let d = this.$store.getters['populationManagement/getSavedList'];

            let defaultPopulation = {
              id: '-1',
              name: 'base-characteristics',
              filterData: {
                logicalOperator: 'and',
                populationName: 'Base Characteristics',
                rules: []
              }
            };

            d.unshift(defaultPopulation);

            this.savedPopulations = d;

            let savedPopulationFilters =
              this.populationStore.getCurrentPopulationSelection();

            if (!savedPopulationFilters.id) {
              this.characteristicsSelected = d[0];
              this.populationStore.setCurrentPopulationSelection(
                this.characteristicsSelected
              );
            } else {
              this.characteristicsSelected =
                this.populationStore.getCurrentPopulationSelection();
            }
          });
      },
      onUpdateDate() {
        this.populationStore.setDirty(true);
      },
      criteriaRedrawKeyFormatted(type) {
        let k = 1;

        if (this.criteriaRedrawKey[type]) {
          k = this.criteriaRedrawKey[type];
        }

        return k + '_' + type + (this.globalSelector ? '_global' : '');
      }
    },
    mounted() {
      this.genderSelected = this.workflowStore.getGender();
      this.dateRangeSelected = this.workflowStore.getDateRange();
      this.ageRangeSelected = this.workflowStore.getAgeRange();
      this.patientStatusSelected = this.workflowStore.getPatientStatus();

      this.populateSavedPopulationList();
    }
  };
</script>